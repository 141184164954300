<template>
  <div class="new-card">
    <div class="title">
      <slot name="title"></slot>
    </div>
    <div class="content">
      <slot></slot>
    </div>
  </div>
</template>
