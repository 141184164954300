
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { Actions, Getters, Mutations } from '@/store/enums/TenantEnums';
import { Actions as StoreActions } from '@/store/enums/StoreEnums';
import toasts from '@/utils/toasts';
import { capitalize } from '@/utils/text';
import timeUtil from '@/utils/time';
import moment from 'moment';
import store from '@/store';
import SearchHeader from '@/views/new-design/layout/SearchHeader.vue';
import Navigator from '@/views/new-design/layout/Navigator.vue';
import Card from '@/views/new-design/components/Card.vue';
import DataTable from '@/components/datatables/KTDataTable.vue';
import EmptyList from '@/components/EmptyList.vue';
import useBreakpoints from 'vue-next-breakpoints';

export default defineComponent({
  name: 'search-tenant-page',
  props: {
    customClass: {
      type: String,
      default: 'mx-auto',
    },
  },
  mounted() {
    this.setFormSubmitted();
    this.clearForm();
    this.clearLists();
    this.getViewedHistory();

    setTimeout(() => {
      store.dispatch(StoreActions.REMOVE_BODY_CLASSNAME, 'page-loading');
    }, 500);
  },
  components: {
    SearchHeader,
    Navigator,
    Card,
    DataTable,
    EmptyList,
  },
  data: () => ({
    loading: false,
    formSubmitted: false,
    errorMessages: [],
    table: {
      items: [],
      currentPage: 1,
      totalPages: 0,
      perPage: 15,
      total: 0,
    },
    viewedTable: {
      items: [],
      currentPage: 1,
      totalPages: 0,
      perPage: 15,
      total: 0,
    },
    hasResult: false,
    loadingViewedHistory: false,
  }),
  computed: {
    ...mapGetters({
      searhTenantErrors: Getters.GET_SEARCH_TENANT_ACTION_ERROR,
      allTenants: Getters.GET_ALL_TENANTS,
      tenants: Getters.GET_SEARCH_TENANT_LIST,
      viewedHistory: Getters.GET_VIEWED_HISTORY,
    }),
    formattedViewedHistory() {
      return this.viewedHistory.references.map((item) => {
        item.name = `${item.tenant.first_name} ${item.tenant.last_name}`;
        item.address = `${item.tenant_review.address_line}, ${item.tenant_review.city}, ${item.tenant_review.state}, ${item.tenant_review.post_code}`;
        item.date_created = this.formatDate(item.viewed_at);
        item.viewedBy = `${item.visitor.first_name} ${item.visitor.last_name}`;

        return item;
      });
    },
    breakpoint(){
      return useBreakpoints({
        mobile: 800,
        desktop: [801],
      });
    },
    isMobile() {
      return (this.breakpoint as any)?.mobile?.matches;
    },
    viewedTenantHeaders(){
      return [
        { name: 'Name', key: 'name' },
        { name: 'Property Address', key: 'address', hidden: this.isMobile },
        { name: 'Viewed Date', key: 'date_created' },
        { name: 'Viewed By', key: 'viewedBy', hidden: this.isMobile },
      ]
    },
    searchHeader(){
      return [
        { name: 'Name', key: 'first_name' },
        { name: 'Date of Birth', key: 'date_of_birth' },
        { name: 'Email', key: 'email', hidden: this.isMobile },
      ]
    }
  },
  methods: {
    ...mapActions({
      searchTenant: Actions.SEARCH_TENANT,
      fetchViewedHistory: Actions.FETCH_VIEWED_HISTORY,
    }),
    dateFormat(date: any) {
      return timeUtil.dateFormat(date);
    },
    capitalizeStr(str: any) {
      return capitalize(str);
    },
    clearForm() {
      (this.$refs.searchHeader as any).clear();
    },
    clearLists() {
      if (this.$route.query.ref == 'dashboard') {
        return false;
      }
      store.commit(Mutations.SET_SEARCH_TENANT_LIST, {});
    },
    setFormSubmitted() {
      if (
        typeof this.$route.query.ref !== 'undefined' &&
        this.$route.query.ref === 'dashboard'
      ) {
        this.formSubmitted = true;
      }
    },
    handleResetForm() {
      this.errorMessages = [];
      this.loading = false;
      this.formSubmitted = false;
    },
    handleSubmitSearchTenant(payload) {
      if (this.loading) return;

      this.loading = true;
      this.formSubmitted = true;
      this.errorMessages = [];

      const params = {
        first_name: payload.first_name,
        last_name: payload.last_name,
        date_of_birth: payload.date_of_birth
          ? moment(payload.date_of_birth).format('YYYY-MM-DD')
          : null,
      };

      const isValid = this.validateInputs(params);
      if (!isValid) {
        this.loading = false;
        return false;
      }

      this.searchTenant(params)
        .then(() => {
          this.loading = false;
          this.errorMessages = [];
          this.clearForm();
          this.hasResult = true;
        })
        .catch(() => {
          this.loading = false;
          const { errors, message } = this.searhTenantErrors;
          this.errorMessages = [];
          toasts.error(errors, message);
        });
    },

    validateInputs(fieldValues) {
      for (const [key, value] of Object.entries(fieldValues)) {
        if (typeof value === 'undefined' || value === '' || value === null) {
          this.errorMessages[`${key}`] = `${capitalize(key).replace(
            /_/g,
            ' '
          )} is required`;
        }
      }

      if (Object.values(this.errorMessages).length === 0) {
        return true;
      }

      return false;
    },
    viewProfile(tenant) {
      this.$router.push({
        name: 'view-tenant-reports',
        params: { tenantId: tenant.id },
      });
    },
    viewHistory(history) {
      this.$router.push({
        name: 'view-tenant-reports',
        params: { tenantId: history.tenant.id },
      });
    },
    async getViewedHistory() {
      this.loadingViewedHistory = true;

      await this.fetchViewedHistory()
        .then(() => {
          this.viewedTable.items = this.formattedViewedHistory;
          this.viewedTable.currentPage =
            this.viewedHistory.pagination.current_page;
          this.viewedTable.totalPages =
            this.viewedHistory.pagination.total_pages;
          this.viewedTable.perPage = this.viewedHistory.pagination.per_page;
          this.viewedTable.total = this.viewedHistory.pagination.total;
        })
        .finally(() => (this.loadingViewedHistory = false));
    },
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY');
    },
  },
  watch: {
    tenants(values) {
      if (values.length > 0) {
        this.loading = true;
        this.formSubmitted = true;
      }
    },
  },
  setup() {
    // show page loading
    store.dispatch(StoreActions.ADD_BODY_CLASSNAME, 'page-loading');
  },
});
